// This file has been modified by our AST polish script
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";
import { renderToString } from 'react-dom/server';
import { getServerState } from 'react-instantsearch';
import I18nWrapper from '/lib/i18n';
import { AlgoliaContext } from '/context/AlgoliaContext';
import { PageParamsProvider as PageParamsProvider__ } from "@plasmicapp/react-web/lib/host";
import { PlasmicIndex } from "../plasmic_components/plasmic/placeit_home_extras/PlasmicIndex";
import { useRouter } from "next/router";
function Index({
  serverState,
  serverUrl
}) {
  // Use PlasmicIndex to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicIndex are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, PlasmicIndex is wrapped by your project's global
  // variant context providers. These wrappers may be moved to
  // Next.js Custom App component
  // (https://nextjs.org/docs/advanced-features/custom-app).
  return <PageParamsProvider__ route={useRouter()?.pathname} params={useRouter()?.query} query={useRouter()?.query}><AlgoliaContext.Provider value={{
      serverState,
      serverUrl
    }}><PlasmicIndex /></AlgoliaContext.Provider></PageParamsProvider__>;
}
export default Index;
export async function getServerSideProps({
  req
}) {
  const protocol = req.headers.referer?.split('://')[0] || 'https';
  const serverUrl = `${protocol}://${req.headers.host}${req.url}`;
  const serverState = await getServerState(<I18nWrapper>
            <Index serverUrl={serverUrl} />
          </I18nWrapper>, {
    renderToString
  });
  return {
    props: {
      serverState,
      serverUrl
    }
  };
}